import React from 'react'
import './Logos.css'
import BoltShift from '../../Assets/Boltshift.jpg'
import Shape from '../../Assets/Shape.jpg'
import Spherule from '../../Assets/Spherule.jpg'
import LightBox from '../../Assets/Lightbox.jpg'
import Featherdev from '../../Assets/FeatherDev.png'

function Logos() {
  return (
    <div className="container-fluid">
      <div className="logo-con">
        <div className="row ">
          <div className="logos"></div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <div className="logo">
              <img src={BoltShift} alt={BoltShift} /> <p>Boltshift</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <div className="logo">
              <img src={LightBox} alt={LightBox} /> <p>Lightbox</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <div className="logo">
              <img src={Featherdev} alt={Featherdev} /> <p>Featherdev</p>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <div className="logo">
              <img src={Spherule} alt={Spherule} /> <p>Spherule</p>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6 col-6">
            <div className="logo">
              <img src={Shape} alt={Shape} /> <p>GlobalBank</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logos
