import React, { useState } from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import SearchStaff from '../SearchStaffMembers/SearchStaffMembers'
import './TaskAssignment.css'
import api from '../../api'

function TaskAssignment() {
  const API_KEY = api
  const currentDate = new Date().toISOString().split('T')[0]
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false })
  const [formData, setFormData] = useState({
    SrNo: '',
    Category: '2D',
    Plot_Size: '',
    Num_floors: 'G',
    Assigned_date: currentDate,
    Assigned_time: currentTime,
    Assigned_to: '',
    Delivery_date: currentDate,
    Delivery_time: currentTime,
    Comments: '',
    Uploads_files: null,
  })

  const Category = [
    { value: '2D', label: '2D' },
    { value: '3D', label: '3D' },
    { value: 'VR', label: 'VR' },
    { value: 'CUT ISO', label: 'CUT ISO' },
  ]

  const NumFloors = [
    { value: 'G', label: 'G' },
    { value: 'G+1', label: 'G+1' },
    { value: 'G+2', label: 'G+2' },
    { value: 'G+3', label: 'G+3' },
    { value: 'G+4', label: 'G+4' },
    { value: 'G+5', label: 'G+5' },
    { value: 'G+6', label: 'G+6' },
    { value: 'G+7', label: 'G+7' },
  ]
  const handleChange = (e) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    } else {
      setFormData({ ...formData, [e.name]: e.value })
    }
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setFormData({ ...formData, Uploads_files: file || null })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const formDataToSend = new FormData()
      for (const key in formData) {
        formDataToSend.append(key, formData[key])
      }

      const res = await axios.post(
        `${API_KEY}/api/TaskAssignment`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (res.data.success) {
        setFormData({
          SrNo: '',
          Category: '2D',
          Plot_Size: '',
          Num_floors: 'G',
          Assigned_date: currentDate,
          Assigned_time: currentTime,
          Assigned_to: '',
          Delivery_date: currentDate,
          Delivery_time: currentTime,
          Comments: '',
          Uploads_files: null,
        })
      }

      toast.success('Data Successfully Inserted ')
    } catch (error) {
      toast.error('Error submitting data:', error)
    }
  }

  const handleStaffSelect = (selectedStaff) => {
    setFormData({ ...formData, Assigned_to: selectedStaff })
  }

  return (
    <div className="task-form">
      <h2 className="text-center">Task Assignment</h2>
      <div className="form-container"></div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Sr No</label>
          <input
            type="number"
            name="SrNo"
            value={formData.SrNo}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group w-100 ">
          <label>Category </label>

          <Select
            options={Category}
            name="Category"
            value={Category.find(
              (option) => option.value === formData.Category,
            )}
            onChange={handleChange}
            className="category"
          />
        </div>
        <div className="form-group">
          <label>Plot Size</label>
          <input
            type="number"
            name="Plot_Size"
            value={formData.Plot_Size}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label> Number of Floors</label>

          <Select
            options={NumFloors}
            name="Category"
            value={NumFloors.find(
              (option) => option.value === formData.Num_floors,
            )}
            onChange={handleChange}
            className="category"
          />
        </div>

        <div className="form-group">
          <label> Assigned Date</label>

          <input
            type="date"
            name="Assigned_date"
            value={formData.Assigned_date}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Assigned Time</label>
          <input
            type="time"
            name="Assigned_time"
            value={formData.Assigned_time}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Assigned To</label>
          <SearchStaff onStaffSelect={handleStaffSelect} />
        </div>

        <div className="form-group w-100">
          <label>Delivery Date </label>
          <input
            type="date"
            name="Delivery_date"
            value={formData.Delivery_date}
            onChange={handleChange}
            className="form-control w-100"
          />
        </div>

        <div className="form-group">
          <label>Delivery Time </label>
          <input
            type="time"
            name="Delivery_time"
            value={formData.Delivery_time}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Comments </label>
          <textarea
            name="Comments"
            value={formData.Comments}
            onChange={handleChange}
            className="form-control"
            rows={3}
          ></textarea>
        </div>
        <div className="form-group">
          <label>File Upload </label>
          <input
            type="file"
            accept=".zip,.rar,.7zip"
            name="file"
            onChange={handleFileChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      <ToastContainer />
    </div>
  )
}

export default TaskAssignment
