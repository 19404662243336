import React, { useState } from 'react'
import './AdminPanel.css'
import { LuLogOut } from 'react-icons/lu'
import { FaBlogger } from 'react-icons/fa'
import { IoIosDocument } from 'react-icons/io'
import { MdReviews } from 'react-icons/md'
import { SiCoursera } from 'react-icons/si'
import JobListingForm from '../JobListingForm/JobListingForm'
import CertificateForm from '../CeritifcateForm/CertificateForm'
import BlogForm from '../BlogForm/BlogForm'
import DocumentForm from '../DocumentForm/DocumentForm'
import CourseForm from '../CourseForm/CourseForm'
import LibraryForm from '../LibraryForm/LibraryForm'
import { PiCertificateFill } from 'react-icons/pi'
import { BiSolidRegistered } from 'react-icons/bi'
import { MdAssignmentTurnedIn } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import AdminRegistrationForm from '../AdminRegistrationForm/AdminRegistrationForm'
import TaskAssignment from '../TaskAssignment/TaskAssignment'

const AdminPanel = ({ handleLogout, adminname }) => {
  // console.log(adminname)
  const [activeComponent, setActiveComponent] = useState(null)
  const [loggedIn, setLoggedIn] = useState(true)
  const navigate = useNavigate()

  const openComponent = (component) => {
    console.log(`Opening component: ${component}`)
    setActiveComponent(component)
  }

  const handleLogoutClick = () => {
    try {
      setTimeout(() => {
        toast.success('Logout Successful')
      }, 1000)
      setTimeout(() => {
        setLoggedIn(false)
        handleLogout()
        navigate('/')
      }, 4000)
    } catch (error) {
      console.log(error)
      toast.error('error', error)
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="admin-left-con">
            <div className="col-lg-4 col-md-4 col-sm-4 col-4  min-vh-100">
              <ul className="nav nav-pills flex-column pt-3 admin-nav">
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('blog')}
                    className="nav-link"
                  >
                    <FaBlogger size={28} />{' '}
                    <span className="ms-2 admin-name">Blog</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    onClick={() => openComponent('plan')}
                    className="nav-link"
                  >
                    <IoIosDocument size={32} />
                    <span className="ms-2 admin-name"> Document</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('course')}
                    className="nav-link"
                  >
                    <SiCoursera size={28} />{' '}
                    <span className="ms-2 admin-name"> Course</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('library')}
                    className="nav-link"
                  >
                    <MdReviews size={28} />
                    <span className="ms-2 admin-name"> Library</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('jobListing')}
                    className="nav-link"
                  >
                    <FaBlogger size={28} />{' '}
                    <span className="ms-2 admin-name">Job Listing</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('certificate')}
                    className="nav-link"
                  >
                    <PiCertificateFill size={28} />{' '}
                    <span className="ms-2 admin-name">Certificate</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('adminRegistration')}
                    className="nav-link"
                  >
                    <BiSolidRegistered size={30} />{' '}
                    <span className="ms-2 admin-name">Staff Registration</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => openComponent('taskAssigment')}
                    className="nav-link"
                  >
                    <MdAssignmentTurnedIn size={28} />{' '}
                    <span className="ms-2 admin-name">Task Assignment</span>
                  </Link>
                </li>
              </ul>
              {loggedIn ? (
                <div className="logout">
                  <ul className="nav nav-pills flex-column">
                    <li className="nav-item">
                      <Link onClick={handleLogoutClick} className="nav-link">
                        <LuLogOut />{' '}
                        <span className="ms-2 admin-name">Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-lg-8 col-lg-8 col-sm-8 col-8">
            <div className="admin-right-box">
              {activeComponent ? (
                activeComponent === 'blog' ? (
                  <BlogForm />
                ) : activeComponent === 'plan' ? (
                  <DocumentForm />
                ) : activeComponent === 'course' ? (
                  <CourseForm />
                ) : activeComponent === 'library' ? (
                  <LibraryForm />
                ) : activeComponent === 'jobListing' ? (
                  <JobListingForm />
                ) : activeComponent === 'certificate' ? (
                  <CertificateForm />
                ) : activeComponent === 'adminRegistration' ? (
                  <AdminRegistrationForm />
                ) : activeComponent === 'taskAssigment' ? (
                  <TaskAssignment />
                ) : null
              ) : (
                <div className="welcome-message">
                  Welcome to the{' '}
                  <span style={{ textTransform: 'capitalize' }}>
                    {adminname}
                  </span>{' '}
                  Admin Console of JCloudWork!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPanel
