import React, { useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import './Login.css'
import api from '../../api'
import { toast, ToastContainer } from 'react-toastify'

function Login({ handleuserLogin }) {
  const API_KEY = api
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const handleLogin = async () => {
    setIsLoading(true)

    try {
      const response = await axios.post(`${API_KEY}/api/login`, {
        username,
        password,
      })
      console.log(response)
      if (response.data.success) {
        // console.log(response.data.user.user)

        toast.success('Login Successful')
        handleuserLogin(response.data)
        setTimeout(() => {
          navigate('/user-console')
        }, 2000)
      } else {
        setError(response.data.message)
        navigate('/login')
      }
    } catch (error) {
      setError('Login error. Please try again.')
    }

    setIsLoading(false)
  }

  // if (redirect) {
  //   return <Navigate to="/user-console" />
  // }
  return (
    <div>
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 d-block m-auto ">
            <div className="login-box  register-box card rounded-4 my-5">
              <div className="login-contents">
                <h3 className="text-login ">Login</h3>
              </div>

              <div className="login-line"></div>
              <div className="login-body">
                <form onSubmit={handleLogin}>
                  {/* <!-- Email Field --> */}
                  <label htmlFor="username">Username</label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter the username"
                      name="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                  {/* <!-- Password Field --> */}
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder=" Enter the Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <p className="forgot-password-link">
                      <a href="/forgot-password">Forgot password?</a>
                    </p>
                  </div>
                  {/* <!-- Submit Button --> */}
                  <div className="form-group">
                    <button
                      className="btn login-btn "
                      onClick={handleLogin}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                  </div>

                  {error && <p className="error">{error}</p>}

                  {/* Loading indicator */}
                </form>
                <p className="py-2 allready-account">
                  Already have an account?
                  <Link className="signup" to="/signup">
                    Signup
                  </Link>
                </p>
              </div>
            </div>
            {isLoading && <div className="loading-indicator">Loading...</div>}{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
