import React, { useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './AdminRegistrationForm.css'
import api from '../../api'

const AdminRegistrationForm = () => {
  const API_KEY = api
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    role: 'admin',
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${API_KEY}/api/admin-register`,
        formData,
      )

      if (response.data.success) {
        toast.success('Admin registered successfully')
        // Reset the form fields
        setFormData({
          name: '',
          email: '',
          password: '',
          phoneNumber: '',
          role: 'admin',
        })
      } else {
        toast.error('Error registering admin')
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMessage('Email already exists. Please choose a different one.')
      } else {
        setErrorMessage('Registration failed. Please try again later.')
        toast.error('Error registering admin')
      }
    }
  }

  return (
    <div className="admin-registration-form">
      <h2 className="text-center">Admin Registration</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        {/* Added role field */}
        <div className="form-group">
          <label htmlFor="role">Role:</label>
          <select
            name="role"
            id="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="admin">Admin</option>
            <option value="staff">Staff</option>
          </select>
        </div>
        <button type="submit">Register</button>
      </form>

      <ToastContainer />
    </div>
  )
}

export default AdminRegistrationForm
