import React from 'react'
import './Play.css'
import videoFile from '../../vedio/mockjcloud01_1.mp4'

function Play() {
  return (
    <div className="playVedio-con">
      <div className="container">
        <div className="play-vedio">
          <video controls>
            <source src={videoFile} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Play
