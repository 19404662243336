import React from 'react'
import './Home.css'
import homeImg from '../../Assets/HomeImage.png'
import { FaLongArrowAltRight } from 'react-icons/fa'
import circleImg from '../../Assets/Circle.jpg'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="leftside-content">
              <div className="Archi">
                <h2>Renowned Architectural</h2>
              </div>
              <div className="home-para">
                <p>
                  At <span className="jcloudtext">Jcloudwork,</span> we blend
                  innovation with artistry to create buildings that stand the
                  test of time. Discover our commitment to eco-friendly
                  architecture that shapes a better, greener future.
                </p>
              </div>
              <button>
                <Link to="/blogs">
                  Learn More{' '}
                  <span>
                    <FaLongArrowAltRight color="white" size={25} />
                  </span>
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="rightside-content">
              <div className="home-img">
                <img src={homeImg} alt="homeimg" />
              </div>

              <div className="circle-img">
                <Link to="play">
                  <img src={circleImg} alt="circle-img" />
                </Link>
              </div>

              <div className="project">
                <div className="unique-style">
                  <h3>300+</h3>
                  <p>Unique Style</p>
                </div>
                <div className="Project-Finished">
                  <h3>200+</h3>
                  <p>Project Finished</p>
                </div>
                <div className="Happy-Customers">
                  <h3>500+</h3>
                  <p>Happy Customers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
