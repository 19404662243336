import React from 'react'
import './Residential.css'
import ShivaBefore from '../../Assets/ShivaBefore.jpg'
import ShivaAfter from '../../Assets/ShivaAfter.jpg'
import ArtificalBefore from '../../Assets/ArtificalBefore.jpg'
import ArtificalAfter from '../../Assets/ArtificalAfter.jpg'

function Residential() {
  return (
    <div className="container">
      <div className="residential-con">
        <div className="Residential">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <div className="residetial-content">
                <h1>Residential Development</h1>
                <div className="verital-line"></div>
              </div>
            </div>

            <div className="col-lg-7 col-md-7 col-sm-12 col-12">
              <p>
                At Jcloudwork, we specialize in designing residential spaces
                that are as unique as you. Our homes blend aesthetic elegance
                with practical functionality, ensuring every space is tailored
                to fit your lifestyle. Whether it's a cozy apartment or a
                sprawling estate, our focus on quality and detail brings your
                vision of the perfect home to life. Experience the blend of
                comfort, style, and sustainability with us.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 col-12">
            <div className="residential-img">
              <img src={ShivaBefore} alt={ShivaBefore} />
              <h2>Shiva Stuthi Residence Before </h2>
              <p>Jcloudwork</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-12">
            <div className="residential-img">
              <img src={ShivaAfter} alt={ShivaAfter} />
              <h2>Shiva Stuthi Residence After </h2>
              <p>Jcloudwork</p>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-6 col-md-6 col-12 col-12">
            <div className="residential-img">
              <img src={ArtificalBefore} alt={ArtificalBefore} />
              <h2>Artificial Lighting Before </h2>
              <p>Jcloudwork</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-12">
            <div className="residential-img">
              <img src={ArtificalAfter} alt={ArtificalAfter} />
              <h2>Artificial Lighting After</h2>
              <p>Jcloudwork</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Residential
