import React from 'react'
import './SearchResultGridCertificate.css'

const SearchResultGrid = ({ results, onResultClick }) => {
  return (
    <div className="serch-con">
      <div className="container serchresult">
        <h3 className="text-center">Search Results</h3>
        <div className="serch-grid">
          <div className="row ">
            {results.length > 0 ? (
              results.map((result, i) => (
                <div
                  className="serch-card g-2 col-lg-3 col-md-6 col-sm-6 col-12 "
                  key={i}
                >
                  <div className="card blog-card ">
                    <div className="card-body">
                      <h4 onClick={() => onResultClick(result.studentCode)}>
                        {result.name} - {result.studentCode}
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-danger">No results found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchResultGrid
