// StaffAdminLogin.js
import React, { useState } from 'react'
import axios from 'axios'
import './StaffAdminLogin.css'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import api from '../../api'

const StaffAdminLogin = ({ onLogin }) => {
  const API_KEY = api
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })

  const navigate = useNavigate()

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${API_KEY}/api/staff-login`,
        credentials,
      )

      // console.log(response)
      if (response.data.role === 'admin' || response.data.role === 'staff') {
        // console.log(response.data)
        onLogin(response.data.id, response.data.role, response.data.name)
        if (response.data.role === 'admin') {
          toast.success('Login Successful')
          setTimeout(() => {
            navigate('/admin-console')
          }, 2000)
        } else {
          navigate('/staff-console')
        }
      } else {
        console.error('Invalid role received from the server')
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error during login:', error)
      console.error('Error response:', error.response)
      toast.error('Error response:', error.response)
      setError('Login error. Please try again.') // Log the response for more details
    }
    setIsLoading(false)
  }

  return (
    <div>
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 d-block m-auto ">
            <div className="staff-login-box  register-box card rounded-4 my-5">
              <div className="staff-login-contents">
                <h3 className="staff-login-text "> Staff-Login</h3>
              </div>

              <div className="staff-login-line"></div>
              <div className="staff-login-body">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email">Email</label>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email address"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password">Password</label>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter the password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <button className="btn staff-login-btn" type="submit">
                    {isLoading ? 'Logging in...' : 'Login'}
                  </button>
                  {error && <p className="error">{error}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffAdminLogin
