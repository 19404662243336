import React from 'react'
import './OurProjects.css'
import videoFile from '../../vedio/mockjcloud01_1.mp4'

function OurProjects() {
  return (
    <div className="our-projects-con">
      <div className="container">
        <div className="our-projects">
          <div className="project-content">
            <h1> Our Best Projects</h1>
            <p>
              Explore our portfolio of standout projects, where each design
              narrates a story of innovation, functionality, and aesthetic
              brilliance.
            </p>
          </div>
          <div className="project-vedio">
            <video controls>
              <source src={videoFile} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProjects

// <div className="play-btn">
//             <img src={play} alt={play} />
//           </div>
//           <div className="project-img">
//             <img src={project} alt={project} />
//           </div>
//           <div className="project-dots">
//             <div className="notclick"></div>
//             <div className="notclick"></div>
//             <div className="click"></div>
//             <div className="notclick"></div>
//             <div className="notclick"></div>
//           </div>
