import React, { useState } from 'react'
import axios from '../../api/axios'
import './Registration.css'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import api from '../../api'

function Registration() {
  const API_KEY = api
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate() // Use the useNavigate hook

  const handleRegistration = async () => {
    try {
      const response = await axios.post(`${API_KEY}/api/register`, {
        username,
        password,
      })

      if (response && response.status === 200) {
        setIsRegistered(true)
        setErrorMessage('')
        toast.success('User Registration Successful')
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      }
    } catch (error) {
      console.error('Registration failed', error)
      if (error.response && error.response.status === 500) {
        setErrorMessage(
          'Username already exists. Please choose a different one.',
        )
      } else {
        setErrorMessage('Registration failed. Please try again later.')
      }
    }
  }

  return (
    <div className="">
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 d-block m-auto ">
            <div className="login-box register-box card rounded-4 my-5">
              <div className="login-contents">
                <h3 className="text-login ">Registration</h3>
              </div>

              <div className="login-line"></div>
              <div className="login-body">
                {/* <!-- Email Field --> */}
                <label htmlFor="username">Username</label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter the username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                </div>
                {/* <!-- Password Field --> */}
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder=" Enter the Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                {/* <!-- Submit Button --> */}
                <div className="form-group">
                  <button
                    onClick={handleRegistration}
                    className="btn register-btn "
                  >
                    Register
                  </button>
                </div>

                {/* Loading indicator */}

                <p className="py-2 allready-account">
                  Already have an account?
                  <Link className="signup" to="/login">
                    SignIn
                  </Link>
                </p>
                {isRegistered && (
                  <p className="success-message">
                    Registration successful. Redirecting...
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration
