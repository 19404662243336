import React from 'react'
import './SearchBoxCertificate.css'

const SearchBox = ({ studentCode, setStudentCode, onSearch }) => {
  return (
    <div className="serch-con">
      <div className="">
        <div className="row justify-content-center">
          <div className=" col-lg-6 col-md-12 d-block m-auto  ">
            <div className="serch-box">
              <div className="form-group w-100">
                <label className="mb-2"> Enter your student code :</label>
                <input
                  type="text"
                  className="form-control"
                  value={studentCode}
                  onChange={(e) => setStudentCode(e.target.value)}
                />
              </div>
              <div className="form-group w-25">
                <button onClick={onSearch} className="btn btn-serch">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBox
