import React from 'react'
import './HomeDesign.css'
import morden from '../../Assets/Morden.jpg'
import quality from '../../Assets/Quality.jpg'
import Affordable from '../../Assets/Affordable.jpg'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function HomeDesign() {
  return (
    <div className="Home-design">
      <div className="container">
        <div className="home-center-content">
          <h1>We are World’s Leading Home Designers</h1>
          <p>
            It's for great minutes, minor achievements, and in the middle
            between.It's for great minutes,{' '}
          </p>
        </div>
        <div className="home-bottom-content">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card">
                <img src={morden} alt={morden} />
                <div className="card-body-section">
                  <h2>Modern Designs</h2>
                  <p>
                    At Jcloudwork, we specialize in modern design that combines
                    sleek minimalism with functional innovation. Our approach
                    sets the stage for architecture that's not just contemporary
                    but timeless.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card">
                <img src={quality} alt={quality} />
                <div className="card-body-section">
                  <h2>Quality Work</h2>
                  <p>
                    Our commitment at Jcloudwork is to deliver unparalleled
                    quality, ensuring excellence and precision in every design
                    and build.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card">
                <img src={Affordable} alt={Affordable} />
                <div className="card-body-section">
                  <h2>Affordable Prices</h2>
                  <p>
                    At Jcloudwork, we believe exceptional architecture should be
                    accessible. Our pricing is competitive, ensuring top-notch
                    design is affordable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homedesign-btn">
          <button>
            <Link to="/documentlist">
              Learn More{' '}
              <span>
                <FaLongArrowAltRight color="white" size={25} />
              </span>
            </Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default HomeDesign
