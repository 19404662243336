import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import api from '../../api'

const SearchStaffMembers = ({ onStaffSelect }) => {
  const API_KEY = api
  const [options, setOptions] = useState([])
  const [selectedStaff, setSelectedStaff] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_KEY}/api/getAllName`)
        const formattedOptions = response.data.result.map((item) => ({
          label: item.name,
          value: item.name,
        }))
        setOptions(formattedOptions)

        if (formattedOptions.length > 0) {
          setSelectedStaff(formattedOptions[0])
          onStaffSelect(formattedOptions[0].value)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [onStaffSelect])

  const handleStaffSelect = (selectedOption) => {
    setSelectedStaff(selectedOption)
    onStaffSelect(selectedOption.value)
  }

  return (
    <Select
      options={options}
      value={selectedStaff}
      onChange={handleStaffSelect}
      className="category"
    />
  )
}

export default SearchStaffMembers
