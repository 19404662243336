import React from 'react'
import './DocumentPagination.css'

function DocumentPagination({ page, setPage, filteredDocuments }) {
  const totalPage = Math.ceil(filteredDocuments.length / 12)

  const selectedPageHandler = (selectedPage) => {
    if (selectedPage >= 1 && selectedPage <= totalPage && selectedPage !== page)
      setPage(selectedPage)
  }

  return (
    <div className="pagination">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-end ">
          <li className={`page-item ${page > 1 ? '' : 'disabled'}`}>
            <span
              onClick={() => selectedPageHandler(page - 1)}
              className={`page-link `}
            >
              Prev
            </span>
          </li>

          {[...Array(totalPage)].map((ele, i) => {
            let pageNumber = i + 1
            return (
              <li
                className={`page-item ${page === pageNumber ? 'active' : ''} `}
                key={i}
              >
                <span
                  className="page-link"
                  onClick={() => selectedPageHandler(pageNumber)}
                >
                  {pageNumber}
                </span>
              </li>
            )
          })}
          <li className={`page-item ${page < totalPage ? '' : 'disabled'}`}>
            <span
              className="page-link"
              onClick={() => selectedPageHandler(page + 1)}
            >
              Next
            </span>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default DocumentPagination
