import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchBox from '../../Components/SearchBoxCertificate/SearchBoxCertificate'
import SearchResultGrid from '../../Components/SearchResultGridCertificate/SearchResultGridCertificate'
import './CertificateSearch.css'
import api from '../../api'

const SearchPage = () => {
  const API_KEY = api
  const navigate = useNavigate()
  const [studentCode, setStudentCode] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const handleSearch = async () => {
    try {
      const response = await fetch(`${API_KEY}/search/${studentCode}`)
      if (response.status === 200) {
        const result = await response.json()
        setSearchResults(result)
      } else if (response.status === 404) {
        setSearchResults([])
      } else {
        console.error('Unexpected response status:', response.status)
      }
    } catch (error) {
      console.error('Error searching:', error)
    }
  }

  const handleResultClick = (selectedStudentCode) => {
    navigate(`/${selectedStudentCode}`)
  }

  return (
    <div className="container serch-certificate my-4">
      <h2 className="text-center">Search Page</h2>
      <SearchBox
        studentCode={studentCode}
        setStudentCode={setStudentCode}
        onSearch={handleSearch}
      />

      <SearchResultGrid
        results={searchResults}
        onResultClick={handleResultClick}
      />
    </div>
  )
}

export default SearchPage
