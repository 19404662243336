import React, { useState } from 'react'
import './UserDashboard.css'
import { SiBlogger } from 'react-icons/si'
import { FaClipboardList } from 'react-icons/fa6'
import { AiFillSafetyCertificate } from 'react-icons/ai'
import BlogsPage from '../../Pages/BlogsPage/BlogsPage'
import { LuLogOut } from 'react-icons/lu'

import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IoMdDocument } from 'react-icons/io'
import JobListingsPage from '../../Pages/JobListingsPage/JobListingsPage'
import CertificateSearch from '../../Pages/CertificateSearch/CertificateSearch'
import { Link, useNavigate } from 'react-router-dom'
import UserDocumentForm from '../UserdocumentForm/UserdocumentForm'

const UserDashboard = ({ username, handleLogout }) => {
  // console.log(username.user.username)

  const [activeComponent, setActiveComponent] = useState(null)

  const [loggedIn, setLoggedIn] = useState(true)
  const navigate = useNavigate()

  const openComponent = (component) => {
    console.log(`Opening component: ${component}`)
    setActiveComponent(component)
  }

  const handleLogoutClick = () => {
    try {
      setTimeout(() => {
        toast.success('Logout Successful')
      }, 1000)
      setTimeout(() => {
        setLoggedIn(false)
        handleLogout()
        navigate('/')
      }, 4000)
    } catch (error) {
      toast.error('error', error)
      console.log(error)
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="dashboard-container">
        <div className="container-fluid">
          <div className="row">
            <div className="dashboard-left">
              <div className="col-lg-4 col-md-4 col-sm-4 col-4 min-vh-100">
                <ul className="nav nav-pills flex-column dashboard-nav">
                  <li className="nav-item">
                    <Link
                      onClick={() => openComponent('blogs')}
                      className="nav-link"
                    >
                      <SiBlogger size={23} />{' '}
                      <span className="ms-2 dashboard-name">Blog</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => openComponent('jobsLising')}
                      className="nav-link"
                    >
                      <FaClipboardList size={25} />{' '}
                      <span className="ms-2 dashboard-name">Job Listing</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => openComponent('certificate')}
                      className="nav-link"
                    >
                      <AiFillSafetyCertificate size={25} />{' '}
                      <span className="ms-2 dashboard-name">
                        Verify Certificate
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={() => openComponent('documentForm')}
                      className="nav-link"
                    >
                      <IoMdDocument />
                      <span className="ms-2 dashboard-name">Document Form</span>
                    </Link>
                  </li>
                </ul>
                <div className="logout">
                  <ul className="nav nav-pills flex-column">
                    <li className="nav-item">
                      {loggedIn && (
                        <Link onClick={handleLogoutClick} className="nav-link">
                          <LuLogOut />{' '}
                          <span className="ms-2 admin-name">Logout</span>
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-lg-8 col-sm-8 col-8">
              <div className="admin-right-box">
                {activeComponent ? (
                  activeComponent === 'blogs' ? (
                    <BlogsPage />
                  ) : activeComponent === 'jobsLising' ? (
                    <JobListingsPage />
                  ) : activeComponent === 'certificate' ? (
                    <CertificateSearch />
                  ) : activeComponent === 'documentForm' ? (
                    <UserDocumentForm />
                  ) : null
                ) : (
                  <div className="welcome-message">
                    Welcome,{username.user.username}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDashboard
