// BlogsPage.js
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom' // Import Link from react-router-dom
import './BlogsPage.css'
import api from '../../api'

function BlogsPage() {
  const API_KEY = api
  const [blogs, setBlogs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    // Fetch blogs from the server
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${API_KEY}/api/blogs`)
        setBlogs(response.data)
      } catch (error) {
        console.error('Error fetching blogs:', error)
      }
    }

    fetchBlogs()
  }, [])

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `${API_KEY}/api/blogs/search?search=${searchTerm}`,
      )
      console.log('Blogs searched:', response.data)
      setBlogs(response.data)
    } catch (error) {
      console.error('Error searching blogs:', error)
    }
  }

  const handleReset = async () => {
    // Fetch all blogs again
    try {
      const response = await axios.get(`${API_KEY}/api/blogs`)
      setBlogs(response.data)
      // Clear the search term
      setSearchTerm('')
    } catch (error) {
      console.error('Error fetching blogs:', error)
    }
  }

  // console.log(blogs)

  return (
    <div className="main-blog">
      <div className="blog-page">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className=" col-lg-6 col-md-11 col-12 ">
              <h2>Blogs</h2>
              <div className="blog-serch">
                <input
                  type="text"
                  placeholder="Search Blogs"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className=" btn" onClick={handleSearch}>
                  Search
                </button>
                <button className=" btn" onClick={handleReset}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="blog-page-card">
            <div className="row">
              {blogs.map((blog, id) => (
                <div
                  key={id}
                  className="col-lg-3 col-md-6 col-sm-12 col-12 blog-page"
                >
                  <Link key={blog.id} to={`/blog/${blog.id}`}>
                    <div className="card">
                      <div className="card-header">
                        <h3>{blog.title}</h3>
                      </div>

                      {blog.image && (
                        <img
                          src={`${API_KEY}/${blog.image}`}
                          alt={blog.title}
                        />
                      )}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogsPage
