// FeatureDetail.js
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './LibraryDetails.css'
import api from '../../api'

function FeatureDetail() {
  const API_KEY = api
  const { id } = useParams()
  const [feature, setFeature] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Fetch the specific feature by ID from the server
    fetch(`${API_KEY}/api/get-feature/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setFeature(data)
        console.log(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching feature details:', error)
        setLoading(false)
      })
  }, [id])

  if (loading) {
    return <div className="feature-detail-loading">Loading...</div>
  }

  if (!feature || !Array.isArray(feature.images)) {
    return (
      <div className="feature-detail-not-found">
        Feature not found or images not available
      </div>
    )
  }
  return (
    <div className="feature-detail-con">
      <div className="container">
        <h2>Feature details </h2>
        <div className="row">
          <div className="col-12 feature-con">
            {feature.images.map((image, index) => {
              console.log(image)
              if (image) {
                return (
                  <div className="card" key={index}>
                    <div className="card-header w-100">{feature.title}</div>
                    <img
                      key={index}
                      src={image}
                      alt={image}
                      className="feature-detail-image"
                    />
                    <div className="card-body">
                      <a
                        href={feature.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="feature-detail-link"
                      >
                        <button className="btn feature-btn">Download</button>
                      </a>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureDetail
