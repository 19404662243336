import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './CertificateForm.css'
import api from '../../api'

const CertificateForm = () => {
  const API_KEY = api
  const [formData, setFormData] = useState({
    name: '',
    studentCode: '',
    domain: '',
    specializedIn: [],
    certificateTitle: '',
    creditScore: '',
    year: 2023,
    month: 1,
  })

  const [isActive, setIsActive] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleCheckboxChange = (e) => {
    const { value } = e.target
    const updatedSpecializedIn = [...formData.specializedIn]

    if (updatedSpecializedIn.includes(value)) {
      updatedSpecializedIn.splice(updatedSpecializedIn.indexOf(value), 1)
    } else {
      updatedSpecializedIn.push(value)
    }

    setFormData({ ...formData, specializedIn: updatedSpecializedIn })
  }

  const handleDropdownToggle = () => {
    setIsActive(!isActive)
  }

  const handleDropdownSelect = (value) => {
    setFormData({ ...formData, domain: value })
    setIsActive(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Check if all fields are filled
    for (const key in formData) {
      if (!formData[key]) {
        toast.error(`Please fill in all fields`)
        return
      }
    }

    try {
      const response = await fetch(`${API_KEY}/api/submit-certificate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      console.log('Response Status:', response.status)

      if (response.status === 200) {
        const result = await response.json()
        console.log('Server Response:', result)

        if (result.success) {
          console.log('Form submitted successfully')
          toast.success('Form submitted successfully')
        } else {
          console.error('Error submitting form:', result.error)
          toast.error(`Error: ${result.error}`)
        }
      } else {
        console.error('Unexpected response status:', response.status)
        toast.error('An unexpected error occurred')
      }
    } catch (error) {
      console.error('Error submitting form:', error)
      toast.error('An unexpected error occurred')
    }
  }

  const renderYearOptions = () => {
    new Date().getFullYear()
    const yearOptions = []
    for (let year = 2000; year <= 2035; year++) {
      yearOptions.push(
        <option key={year} value={year} className="year-option">
          {year}
        </option>,
      )
    }
    return yearOptions
  }

  return (
    <div>
      {' '}
      <ToastContainer />
      <div className="certificate-form">
        <h2 className="text-center">Certificate Form</h2>
        <div className="certificate-con">
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label>Name </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field">
              <label> Student Code:</label>
              <input
                type="text"
                className="form-control"
                name="studentCode"
                value={formData.studentCode}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field mb-3">
              <label htmlFor="domain">Domain</label>

              <div className="select-container">
                <select
                  id="domain"
                  className="year-select"
                  value={formData.domain}
                  onClick={handleDropdownToggle}
                  onChange={(e) => handleDropdownSelect(e.target.value)}
                >
                  {[
                    'Civil/Architecture/Interior',
                    'Mechanical',
                    'Electrical',
                    'IT',
                  ].map((domain) => (
                    <option key={domain} value={domain}>
                      {domain}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-field">
              <label htmlFor="specializedIn ">Specialized In</label>
              <div className="select-container">
                <select
                  id="specializedIn"
                  name="specializedIn"
                  className="specilized-btn"
                  multiple
                  value={formData.specializedIn}
                  onChange={handleCheckboxChange}
                >
                  {[
                    '2D Drafting - Autocad',
                    '3D Modeling - Sketchup',
                    'Rendering - Lumion',
                    'Virtual Reality - ShapeSpark',
                    'BIM - Revit',
                    'MEP - Revit',
                    'Structure Design - Revit',
                    'Product Design - Solidworks',
                    'Furniture Design - Rhino',
                    'Visualization - Photoshop',
                  ].map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-field">
              <label>Title of Certificate: </label>
              <input
                type="text"
                className="form-control w-100"
                name="certificateTitle"
                value={formData.certificateTitle}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field">
              <label>Credit Score: </label>
              <input
                type="number"
                className="form-control"
                name="creditScore"
                value={formData.creditScore}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field mb-4">
              <label>Year </label>
              <select
                name="year"
                className="w-100 year-select "
                value={formData.year}
                onChange={handleInputChange}
              >
                {renderYearOptions()}
              </select>
            </div>
            <div className="form-field">
              <label>Month </label>
              <select
                name="month"
                className="w-100 year-select"
                value={formData.month}
                onChange={handleInputChange}
              >
                {[...Array(12).keys()].map((month) => (
                  <option key={month + 1} value={month + 1}>
                    {new Date(2000, month, 1).toLocaleString('en-US', {
                      month: 'long',
                    })}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CertificateForm
