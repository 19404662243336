import React from 'react';
import './App.css';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import Courses from './Pages/Courses/Courses';
import AboutUs from './Pages/AboutUs/AboutUs';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Pages/Login/Login';
import Registration from './Pages/Registration/Registration';
import BlogDetailModal from './Components/BlogDetailModal/BlogDetailModal';
import CourseDetailsPage from './Pages/CourseDetailsPage/CourseDetailsPage';
import DocumentPage from './Pages/DocumentPage/DocumentPage';
import DocumentDetail from './Components/DocumentDetail/DocumentDetail';

import Navbar from './Components/Navbar/Navbar';
import BlogsPage from './Pages/BlogsPage/BlogsPage';
import LibraryPage from './Pages/LibraryPage/LibraryPage';
import LibraryDetails from './Pages/LibraryDetails/LibraryDetails';
import ContactUs from './Pages/ContactUs/ContactUs';
import JobListingsPage from './Pages/JobListingsPage/JobListingsPage';
import CertificateViewer from './Components/CertificateViewer/CertificateViewer';
import CertificateSearch from './Pages/CertificateSearch/CertificateSearch';
import UserDashboard from './Components/UserDashbord/UserDashboard';
import StaffAdminLogin from './Components/StaffAdminLogin/StaffAdminLogin';
import AdminConsole from './Components/AdminPanel/AdminPanel';
import StaffConsole from './Components/StaffConsole/StaffConsole';
import { Toaster } from 'react-hot-toast';
import TaskAssignment from './Components/TaskAssignment/TaskAssignment';
import FooterSection from './Components/FooterSection/FooterSection';
import Play from './Components/Play/Play';
import Privacy from './Components/Privacy/Privacy';
import TermCondition from './Components/TermCondtion/TermCondition';

function App() {
  const currentPath = window.location.pathname;
  const [loggedInRole, setLoggedInRole] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  const [userID, setUserID] = useState(null);
  const [username, setUsername] = useState(null);
  const [adminname, setAdminName] = useState(null);

  const handleLogin = (id, role, name) => {
    // console.log(role)
    setLoggedInRole({ id, role, name });
    setUserID(id);
    setAdminName(name);
    // console.log(name)
  };
  const handleuserLogin = (user) => {
    setLoggedIn({ user });
    setUsername(user);
  };
  const handleLogout = () => {
    setLoggedInRole(null);
    setLoggedIn(null);
  };
  const isConsolePage =
    currentPath === '/user-console' || currentPath === '/admin-console';
  return (
    <BrowserRouter>
      {!isConsolePage && <Navbar />}
      <Toaster />
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/services'
          element={<Services />}
        />
        <Route
          path='/courses'
          element={<Courses />}
        />
        <Route
          path='/about'
          element={<AboutUs />}
        />
        <Route
          path='/contact'
          element={<ContactUs />}
        />

        <Route
          path='/signup'
          element={<Registration />}
        />
        <Route
          path='/documentlist'
          element={<DocumentPage />}
        />
        <Route
          path='/blog/:id'
          element={<BlogDetailModal />}
        />
        <Route
          path='/courses/:id'
          element={<CourseDetailsPage />}
        />
        <Route
          path='/documents/:id'
          element={<DocumentDetail />}
        />
        <Route
          path='/blogs'
          element={<BlogsPage />}
        />
        <Route
          path='/library'
          element={<LibraryPage />}
        />
        <Route
          path='/library/:id'
          element={<LibraryDetails />}
        />
        <Route
          path='/job-listing'
          element={<JobListingsPage />}
        />
        <Route
          path='/:studentcode'
          element={<CertificateViewer />}
        />
        <Route
          path='/certificate-verification'
          element={<CertificateSearch />}
        />
        <Route
          path='/certificate-verification'
          element={<CertificateSearch />}
        />

        <Route
          path='/user-console'
          element={
            loggedIn ? (
              <UserDashboard
                handleLogout={handleLogout}
                username={username}
              />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/login'
          element={<Login handleuserLogin={handleuserLogin} />}
        />
        <Route
          path='/admin-console'
          element={
            loggedInRole && loggedInRole.role === 'admin' ? (
              <AdminConsole
                handleLogout={handleLogout}
                adminname={adminname}
              />
            ) : (
              <Navigate to='/login-admsta' />
            )
          }
        />
        {/* <Route
          path='/admin-console'
          element={<AdminConsole handleLogout={handleLogout} />}
        /> */}

        <Route
          path='/play'
          element={<Play />}
        />
        <Route
          path='/privacy-policy'
          element={<Privacy />}
        />

        <Route
          path='/staff-console'
          element={
            loggedInRole && loggedInRole.role === 'staff' ? (
              <StaffConsole
                handleLogout={handleLogout}
                userId={userID}
              />
            ) : (
              <Navigate to='/login-admsta' />
            )
          }
        />

        <Route
          path='/login-admsta'
          element={<StaffAdminLogin onLogin={handleLogin} />}
        />

        <Route
          path='/task-assignment'
          element={<TaskAssignment onLogin={handleLogin} />}
        />
        <Route
          path='/term-condition'
          element={<TermCondition />}
        />
      </Routes>
      {!isConsolePage && <FooterSection />}
    </BrowserRouter>
  );
}

export default App;
