import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './StaffConsole.css'
import api from '../../api'

const StaffConsole = ({ userId }) => {
  // console.log(userId)
  const API_KEY = api
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${API_KEY}/api/getAllTasksByStaff?userId=${userId}`,
        )
        console.log(response)
        if (response.data.success) {
          setTasks(response.data.result)
          setLoading(true)
        } else {
          console.error('Error fetching tasks:', response.data.error)
        }
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    }

    fetchTasks()
  }, [userId])

  return (
    <div className="staffConsole">
      {loading ? (
        <div className="row justify-content-center">
          <div className=" col-md-6">
            <div className="table-responsive-sm">
              <table className="table table-bordered  p-3 rounded-lg">
                {tasks.map((task, i) => {
                  return (
                    <tbody key={i}>
                      <tr className="text-center">
                        <th colSpan={2}>Assigned Projects</th>
                      </tr>
                      <tr>
                        <td>Task ID</td>
                        <td>{task.SrNo}</td>
                      </tr>
                      <tr>
                        <td>Category</td>
                        <td>{task.Category}</td>
                      </tr>
                      <tr>
                        <td>Plot Size</td>
                        <td>{task.Plot_Size}</td>
                      </tr>
                      <tr>
                        <td>Number of Floors</td>
                        <td>{task.Num_floors}</td>
                      </tr>
                      <tr>
                        <td>Assigned Date:</td>
                        <td>{task.Assigned_date}</td>
                      </tr>
                      <tr>
                        <td>Assigned Time</td>
                        <td>{task.Assigned_time}</td>
                      </tr>
                      <tr>
                        <td>Assigned To</td>
                        <td>{task.Assigned_to}</td>
                      </tr>
                      <tr>
                        <td>Delivery Date</td>
                        <td> {task.Delivery_date}</td>
                      </tr>
                      <tr>
                        <td>Delivery Time</td>
                        <td>{task.Delivery_time}</td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td>{task.Comments}</td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center ">Loading...</p>
      )}
    </div>
  )
}

export default StaffConsole
