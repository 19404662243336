import React from 'react'
import './FooterSection.css'
import Logo from '../../Assets/logo.jpg'
import { Link } from 'react-router-dom'
import { FaFacebookF } from 'react-icons/fa'

import { FaInstagram } from 'react-icons/fa'

function FooterSection() {
  return (
    <div className="footer-con">
      <div className="footer-sec">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-sx-12 footer-learning ">
              <div className="footer-sect-column ">
                <img src={Logo} alt="logo" />
              </div>
              <p className="footer-tag">Learning & Training Redefined</p>

              <div className="footer-social">
                <div className="insta-icon">
                  <Link to="https://www.facebook.com/jcloudwork21/">
                    {' '}
                    <FaFacebookF />
                  </Link>
                </div>
                <div className="insta-icon">
                  <Link to="https://www.instagram.com/jcloudwork">
                    <FaInstagram />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-4 col-xs-4 mx-sm-auto text-center">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 ">
                  <div className="footersec-link">
                    <h3>Stores & Services</h3>
                    <p>
                      <Link to="/services">Services</Link>
                    </p>
                    <p>
                      <Link to="/documentlist">Plans</Link>
                    </p>

                    <p>
                      <Link to="/job-listing" className="FooterLink">
                        Jobs
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <div className="footersec-link">
                    <h3 className="">Help</h3>
                    <p>
                      <Link to="/blogs" className="FooterLink">
                        Blogs
                      </Link>
                    </p>

                    <p>
                      <Link
                        to="/certificate-verification"
                        className="FooterLink"
                      >
                        Certificate Verification
                      </Link>
                    </p>
                    <p>
                      <Link to="/login-admsta" className="FooterLink">
                        Login
                      </Link>
                    </p>
                    <p>
                      <Link to="/contact" className="FooterLink">
                        Contact Us
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <div className="footersec-link">
                    <h3 className="">Resources</h3>
                    <p>
                      <Link to="/courses" className="FooterLink">
                        Courses
                      </Link>
                    </p>
                    <p>
                      <Link to="/term-condition" className="FooterLink">
                        T & C
                      </Link>
                    </p>
                    <p>
                      <Link to="/privacy-policy" className="FooterLink">
                        Privacy Policy
                      </Link>
                    </p>
                    <p>
                      <Link to="/library" className="FooterLink">
                        Library
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                  <div className="footersec-copyright">
                    <p>
                      © Copyright 2022, All Rights Reserved by jcloudwork.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterSection
