// CertificateViewer.js

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './CertificateViewer.css'
import api from '../../api'

const CertificateViewer = () => {
  const { studentcode } = useParams()
  const API_KEY = api
  const [certificateData, setCertificateData] = useState(null)

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        const response = await fetch(`${API_KEY}/certificate/${studentcode}`)

        if (response.status === 200) {
          const result = await response.json()
          console.log('Fetched Certificate Data:', result)
          setCertificateData(result)
        } else if (response.status === 404) {
          setCertificateData(null)
          toast.error('Certificate not found')
        } else {
          console.error('Unexpected response status:', response.status)
          toast.error('An unexpected error occurred')
        }
      } catch (error) {
        console.error('Error fetching certificate data:', error)
        toast.error('An unexpected error occurred')
      }
    }

    fetchCertificateData()
  }, [studentcode])

  return (
    <div className="certificate-container">
      <ToastContainer />
      {certificateData && certificateData.name ? (
        <div>
          <h2 className="text-center">Certificate Details</h2>
          <table className="certificate-table">
            <tbody>
              <tr>
                <td className="table-label">Name:</td>
                <td>{certificateData.name}</td>
              </tr>
              <tr>
                <td>Student Code:</td>
                <td>{certificateData.studentCode}</td>
              </tr>
              <tr>
                <td>Domain:</td>
                <td>{certificateData.domain}</td>
              </tr>
              <tr>
                <td>Specialized In:</td>
                <td>{certificateData.specializedIn}</td>
              </tr>
              <tr>
                <td>Credit Score:</td>
                <td>{certificateData.creditScore}</td>
              </tr>
              <tr>
                <td>Year:</td>
                <td>{certificateData.year}</td>
              </tr>
              <tr>
                <td>Month:</td>
                <td>{certificateData.month}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default CertificateViewer
