import React from "react";

const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d265.4749198148119!2d73.7316966324592!3d24.571805627980382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967efb1803cf5ab%3A0x57b91177b87846fa!2sJcloud%20Work!5e0!3m2!1sen!2sin!4v1715229291556!5m2!1sen!2sin"
        width="1200"
        height="450"
        style={{ border: "0" }} // Changed style attribute to use JavaScript object syntax
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
