import React from 'react'
import HomeSilder from '../../Components/Home/Home'
import Logos from '../../Components/Logos/Logos'
import Residential from '../../Components/Residential/Residential'
import HomeDesign from '../../Components/HomeDesign/HomeDesign'
import Choose from '../../Components/Choose/Choose'
import OurProjects from '../../Components/OurProject/OurProjects'

const Home = () => {
  return (
    <>
      <HomeSilder />
      {/* <Logos /> */}
      <Residential />
      <HomeDesign />
      <Choose />
      <OurProjects />
    </>
  )
}

export default Home
