import React from 'react'
import './Choose.css'
import Drafting from '../../Assets/Drafting.jpg'

function Choose() {
  return (
    <div className="container">
      <div className="choose-con">
        <div className="choose">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <div className="choose-content">
                <h1>Why Should Choose Our</h1>
                <div className="verital-line"></div>
              </div>
            </div>

            <div className="col-lg-7 col-md-7 col-sm-12 col-12 ">
              <p>
                Choosing Jcloudwork means partnering with a team that values
                your vision and brings it to life with expertise, innovation,
                and a commitment to sustainability. We offer personalized
                solutions, ensuring each project is not just built but crafted
                to meet your specific needs and aspirations. With us, your
                architectural dreams are in skilled hands.
              </p>
            </div>
          </div>
        </div>

        <div className="choose-bootom">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="drafting">
                <div className="drafting-content">
                  <h5>2D Drafting</h5>
                  <p>Blueprints of Precision</p>
                </div>
                <div className="horizotal-line"></div>
              </div>
              <div className="drafting">
                <div className="drafting-content">
                  <h5>3D Elevation</h5>
                  <p>Designs</p>
                </div>
                <div className="horizotal-line"></div>
              </div>
              <div className="drafting">
                <div className="drafting-content">
                  <h5>Virtual</h5>
                  <p>Immersive Design Experience</p>
                </div>
                <div className="horizotal-line"></div>
              </div>
              <div className="drafting">
                <div className="drafting-content">
                  <h5>Vastu Services</h5>
                  <p>Harmony in Design</p>
                </div>
                <div className="horizotal-line"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="darfting-img">
                <img src={Drafting} alt={Drafting} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Choose
