// FeaturesGrid.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './LibraryGrid.css';
import api from '../../api';

function FeaturesGrid() {
  const API_KEY = api;
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    // Fetch features from the server when the component mounts
    fetch(`${API_KEY}/api/get-features`)
      .then((response) => response.json())
      .then((data) => setFeatures(data))
      .catch((error) => console.error('Error fetching features:', error));
  }, []);

  return (
    <div className='features-grids'>
      <div className='container'>
        <h2 className='text-center'>Library </h2>
        <div className='row'>
          {features.map((feature, i) => {
            return (
              <div
                className='col-lg-3 col-md-3 col-sm-6 col-12'
                key={i}
              >
                <Link
                  key={feature.id}
                  to={`/library/${feature.id}`}
                  className='feature-card-link'
                >
                  <div className=' featurescard card my-4 rounded-3'>
                    <div className='card-body'>
                      <img
                        src={`${API_KEY}/${feature.image1}`}
                        alt={feature.title}
                        className='features-img'
                      />
                      <h3 className=''>{feature.title}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FeaturesGrid;
